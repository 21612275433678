@import '../../../../defaults.scss';

.geo-json-switcher .x-picker .rs-picker-check {
  height: 34px;
  width: 100%;
}

.geo-json-switcher .x-picker .rs-picker-check .rs-picker-value-count {
  display: none;
}

.geo-json-switcher .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  height: 34px;
  min-width: unset;
}

.geo-json-switcher .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
  font-family: Muli;
  font-size: 13px;
}

.geo-json-switcher .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.geo-json-switcher .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
  border-color: $color-primary;
}

.geo-json-switcher .rs-picker-menu.rs-picker-check-menu,
.rs-picker-menu.rs-picker-select-menu {
  width: 180px;
}
