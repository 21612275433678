@import '../../../../defaults.scss';
@import '../renderer/index.scss';


.report.driving-behaviour .scroll-list {
	display: grid;
	grid-template-columns: 
		minmax(80px, 5%) // item no
		minmax(180px, .2fr) // device
		repeat(5, minmax(80px, .1fr))
		repeat(1, minmax(140px, .2fr)) // odometer
		repeat(3, minmax(80px, .1fr))
		repeat(3, minmax(80px, .1fr)) // idling
		repeat(4, minmax(80px, .1fr)) // shares
	;
}

.report.driving-behaviour .scroll-list .loader
, .report.driving-behaviour .scroll-list .indicator {
	grid-column: 1 / -1;
}

.report.driving-behaviour .scroll-list .headers
, .report.driving-behaviour .scroll-list .item {
	display: contents;
}

.report.driving-behaviour .scroll-list > * > * {
	padding: 15px;
	display: flex;
	gap: .5em;
	align-items: center;
	flex-wrap: wrap;
}

.report.driving-behaviour .scroll-list .headers > * {
	position: sticky;
	top: 0;
	z-index: 1;
	padding-right: 15px;
	border-bottom: 1px solid $color-border;
	font-weight: bold;
	color: black;
	background-color: white;
}

.report.driving-behaviour .scroll-list .headers > * {
	grid-row-end: span 2;
}

.report.driving-behaviour .scroll-list .headers > *:not(.sub-header) > *::first-letter {
	text-transform: capitalize;
}

.report.driving-behaviour .scroll-list .headers > .group-header {
	grid-row-end: span 1;
	border-bottom: none;
	padding-bottom: 7px;
}
.report.driving-behaviour .scroll-list .headers > .group-header.idling {
	grid-column-end: span 3;
}
.report.driving-behaviour .scroll-list .headers > .group-header.shares {
	grid-column-end: span 4;
}
.report.driving-behaviour .scroll-list .headers > .sub-header {
	top: calc(15px + 1em * 1.28581 + 7px);
	padding-top: 0;
	grid-row-end: span 1;
}


.report.driving-behaviour .scroll-list > .item:nth-child(even) > * {
	background-color: $reports-bg-color;
}

.report.driving-behaviour .scroll-list > .headers > *:nth-child(n + 3):nth-child(-n + 10)
, .report.driving-behaviour .scroll-list > .headers > *:nth-child(n + 13)
, .report.driving-behaviour .scroll-list > .item > *:nth-child(n + 3):nth-child(-n + 10)
, .report.driving-behaviour .scroll-list > .item > *:nth-child(n + 13) {
	justify-content: flex-end;
	text-align: right;
}

.report.driving-behaviour .scroll-list > .headers > *:nth-child(n + 11):nth-child(-n + 12) {
	justify-content: center;
}


.report.driving-behaviour .scroll-list > .headers > .sub-header.statistics > *:nth-child(2)
, .report.driving-behaviour .scroll-list > .item > .statistics > *:nth-child(2) {
	width: 2.5em;
}

