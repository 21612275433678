@import "../../../defaults.scss";

.device-sidebar {
	height: 100%;
	width: $sidebar-width;
	background-color: #252c34;
	color: $text-color-main-2;
}
.device-sidebar.collapsed {
	width: auto;
}
.device-sidebar img {
	filter: $text-filter-main-2;
}
.device-sidebar .device-action-bar img:hover, .device-sidebar .toggle img:hover {
	filter: $icon-filter-highlight;
}
.device-sidebar .toggle img {
	height: 16px;
}

.device-sidebar > .device-side-menu {
	height: 100%;
	display: flex;
	flex-direction: column;
}
