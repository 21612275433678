@import '../../../defaults.scss';

.drag-layer .item {
	background-color: transparent;
	border-radius: 5px;
	max-width: max-content;
	max-height: max-content;
	box-shadow: 0px 7px 13px 0px rgba(0,0,0,0.4);
}

.drag-layer .item .action-item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	background-color: #79CDD0;
	border-radius: 5px;
}

.drag-layer .item .action-item img {
	filter: $icon-filter-white;
}