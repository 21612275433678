@import '../../../defaults.scss';

.marker {
  cursor: pointer;
  position: absolute;
  left: -29px;
  top: -29px;
  width: 58px;
  height: 58px;
  background: center no-repeat url('../../../img/markers/marker.png');
}
.unresolved-issue-icon {
  width: 15px;
  display: block;
  height: 12px;
  position: absolute;
  top: 34px;
  left: 3px;
  background: center no-repeat url('../../../img/markers/indicator/incident.png') !important;
}

.red-badge {
  & > div:not(.marker-label) {
    background-color: #c92828 !important;
    ::after {
      background-color: #c92828 !important;
    }
  }
  &::before {
    box-shadow: 0 0 8px 2px #c92828 !important;
  }
  &::after {
    background-color: #c92828 !important;
  }
}
.orange-badge {
  & > div:not(.marker-label) {
    background-color: #de8d0d !important;
    &::after {
      background-color: #de8d0d !important;
    }
    ::after {
      background-color: #de8d0d !important;
    }
  }
  &::before {
    box-shadow: 0 0 8px 2px #de8d0d !important;
  }
  &::after {
    background-color: #de8d0d !important;
  }
}
.green-badge {
  & > div:not(.marker-label) {
    background-color: #1c7903 !important;
    ::after {
      background-color: #1c7903 !important;
    }
  }

  &::before {
    box-shadow: 0 0 8px 2px #1c7903 !important;
  }
  &::after {
    background-color: #1c7903 !important;
  }
}

.marker-badge {
  mask-image: none !important;
  color: white !important;
  -webkit-mask-image: none !important;
  height: 18px !important;
  width: 40px !important;
  font-size: 12px !important;
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: 46px !important;
  left: 10px !important;
}
.marker > div:not(.marker-label) {
  position: absolute;
  width: 100%;
  height: 100%;
  mask-image: url('../../../img/markers/circle.png');
  -webkit-mask-image: url('../../../img/markers/circle.png');
}
.marker.pointer > div:not(.marker-label) {
  mask-image: url('../../../img/markers/pointer.png');
  -webkit-mask-image: url('../../../img/markers/pointer.png');
}

@each $assetType in $assetTypes {
  .marker.#{$assetType}::after {
    @include assetTypeIcon($assetType);
  }
}
.marker > div.marker-label {
  position: absolute;
  left: 50%;
  top: -20px;
  bottom: calc(29px + 0.4px);
}
.marker > div.marker-label > div {
  white-space: nowrap;
  background-color: white;
  padding: 0.25em 0.5em;
  overflow: hidden;
  border: 1px solid hsl(0, 0%, 75%);
  position: relative;
  left: -50%;
  max-width: 200px;
  text-overflow: ellipsis;
  user-select: none;
  color: $text-color-light;
  cursor: pointer;
}

.marker::after {
  content: '';
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  width: 32px;
  height: 32px;
  mask-image: url('../../../img/assetTypes/MD/car.png');
  mask-size: 20px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  -webkit-mask-image: url('../../../img/assetTypes/MD/car.png');
  -webkit-mask-size: 20px;
  -webkit-mask-position: 50% 50%;
  -webkit-mask-repeat: no-repeat;
}

.marker.online > div:not(.marker-label),
.marker.online::after {
  background-color: $device-status-color-online;
}
.marker.active > div:not(.marker-label),
.marker.active::after {
  background-color: $device-status-color-active;
}
.marker.idle > div:not(.marker-label),
.marker.idle::after {
  background-color: $device-status-color-idle;
}
.marker.offline > div:not(.marker-label),
.marker.offline::after {
  background-color: $device-status-color-offline;
}
.marker > div:not(.marker-label),
.marker::after,
.marker.stale > div:not(.marker-label),
.marker.stale::after {
  background-color: $device-status-color-stale;
}

.marker.selected::before {
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 13px;
  left: 13px;
  border-radius: 16px;
  box-shadow: 0 0 8px 2px $device-status-color-stale;
}
.marker > div.marker-label > div {
  color: $device-status-text-color-stale;
  box-shadow: 0 3px 8px 2px $device-label-shadow-color;
}
.marker.online > div.marker-label > div {
  color: $device-status-text-color-online;
}
.marker.active > div.marker-label > div {
  color: $device-status-text-color-active;
}
.marker.idle > div.marker-label > div {
  color: $device-status-text-color-idle;
}
.marker.offline > div.marker-label > div {
  color: $device-status-text-color-offline;
}

.marker.selected > div.marker-label > div {
  box-shadow: 0 3px 10px 2px $device-label-shadow-selected-color;
}
.marker.selected.online::before {
  box-shadow: 0 0 8px 2px $device-status-color-online;
}
.marker.selected.active::before {
  box-shadow: 0 0 8px 2px $device-status-color-active;
}
.marker.selected.idle::before {
  box-shadow: 0 0 8px 2px $device-status-color-idle;
}
.marker.selected.offline::before {
  box-shadow: 0 0 8px 2px $device-status-color-offline;
}
