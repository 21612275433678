@import "../../../defaults.scss";

$padding: 15px;

.property-popup {
	width: min-content;
	height: min-content;
	position: absolute;
	z-index: 1;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
	padding: $padding;
	border: 1px solid $color-border;
	border-radius: 3px;
	background-color: white;
}
.property-popup .header {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
}
.property-popup .header .title {
	flex-grow: 1;
	text-transform: capitalize;
	font-weight: bold;
	color: $text-color-accent;
	overflow: hidden;
	overflow-wrap: break-word;
}
.property-popup .header .actions {
	padding-left: 5px;
}
.property-popup .header .actions .action {
	cursor: pointer;
	color: $text-color-main;
	height: 11px;
}
.property-popup .header .actions .action > img {
	height: 100%;
	display: block;
}